import type { FC } from 'react';
import { useNavigate } from "react-router-dom";
import instance from "../api";
import { useForm, SubmitHandler } from "react-hook-form";
type AdInputs = {
  name?: string;
  img?: string;
  url?: string;
  isEnabled?: boolean;
  order?: number;
};


interface AdFormProps {
  isNew: boolean;
  id?: number;
  values?: {
    name?: string;
    img?: string;
    url?: string;
    isEnabled?: boolean;
    order?: number;
  };
}

const AdForm: FC<AdFormProps> = (props) => {
  let {isNew, values, id} = props;
  const navigate = useNavigate();
  const createAd = async (value: any) => {
    console.log(value);
    await instance.post("/ads", value).then((res) => {
      if (res.status === 201) {
        navigate("/ads", { replace: true });
      }
    });
  };
  const updateAd = async (value: any) => {
    let update = {
      name: value.name,
      img: value.img,
      url: value.url,
      isEnabled: value.isEnabled,
      order: value.order,
    };
    await instance.patch(`ads/${id}`, update).then((res) => {
      console.log(res);
      if (res.status === 200) {
        navigate("/ads", { replace: true });
      }
    });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({defaultValues:{
    name: values?.name,
    img: values?.img,
    url: values?.url,
    isEnabled:values?.isEnabled,
    order: values?.order,
  }});


  const onSubmit: SubmitHandler<AdInputs> = (data) => {
    console.log(data);
    console.log(isNew);
    return isNew ? createAd(data) : updateAd(data);
  };
  return (
    <div>
      <h1>{}</h1>
      <form
        className="box"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="field">
          <label className="label">Name</label>
          <div className="control">
            <input className="input is-medium" {...register("name")} />
          </div>
        </div>
        <div className="field">
          <label className="label">Ad Image Url</label>
          <div className="control">
            <input className="input is-medium" {...register("img")} />
          </div>
        </div>

        <div className="field">
          <label className="label">Ad Url</label>
          <div className="control">
            <input className="input is-medium" {...register("url")} />
          </div>
        </div>
        <div className="field">
          <label className="label">Order</label>
          <div className="control">
            <input
              type="number"
              className="input is-medium"
              {...register("order", {valueAsNumber: true})}
            />
          </div>
        </div>

        <div className="field">
          <label className="checkbox">
            <input
              className="is-medium"
              type="checkbox"
              {...register("isEnabled")}
            />
            Enable Ad
          </label>
        </div>
        <input type="submit" className="button is-primary"/>
      </form>
    </div>
  );
}

export default AdForm;


