import instance from "./api";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export type AuthContextType = {
  token: string | null;
  setToken: (token: string) => void;
};
export const AuthContext = createContext({} as AuthContextType);

const AuthProvider = ({ children }:any):any => {
  
    const [token, setToken_] = useState(localStorage.getItem('token'));

  const setToken = (newToken: string) => {
    setToken_(newToken);
  };
  useEffect(() => {
    if (token) {
      console.log('setting token'+ token)
      instance.defaults.headers.common['Authorization'] =
        "Bearer " + token;
        localStorage.setItem('token',token);
    } else {
      delete instance.defaults.headers.common['Authorization'];
      localStorage.removeItem("token");
    }
  }, [token]);
  const contextValue = useMemo(
    () => ({
      token,
      setToken,
    }),
    [token]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
