import { useQuery } from "@tanstack/react-query";
import { Box, Button, Table } from "react-bulma-components";
import instance from "../api";
import { useNavigate } from "react-router-dom";

export default function AdIndex() {
  const navigate = useNavigate();
  const query = useQuery({
    queryKey: ["ads"],
    queryFn: async () => {
      const response = await instance.get(`/ads`);
      return await response.data;
    },
  });


  async function newAd() {
    navigate("/ads/new");
  }

  async function editAd(adId:number) {
    navigate(`/ads/edit/${adId}`);
  }

  async function deleteAd(adId:number){
    await instance.delete(`ads/${adId}`)
      .then((res)=>{
        query.refetch();
      })
  }

  if (query.isPending) return <p>'Loading...'</p>;
  if (query.error) return <p>'An Error Occured'</p>;
  return (
    <div>
      <Box style={{ width: 1200, margin: 30, padding: 10 }}>
        <Button className="button is-primary" onClick={newAd}>
          New Ad
        </Button>
      </Box>
      <Box style={{ width: 1200, margin: 30, padding: 10 }}>
        <Table>
          <thead>
            <tr>
              <th>title</th>
              <th>Image Url</th>
              <th>Ad Url</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {query.data.map((ad: any) => {
              return (
                <tr key={ad.id}>
                  <td>
                    <a href={ad.url}>{ad.name}</a>
                  </td>
                  <td>
                    
                    <img src={ad.img}></img>
                  </td>
                  <td>
                    <a href={ad.url}>{ad.url}</a>
                  </td>
                  <td>
                    <div className="columns">
                      <Button className="button is-primary column" style={{margin:10, width:100}} onClick={() => editAd(ad.id)}>
                        Edit
                      </Button>
                      <Button className="button is-primary column" style={{margin:10, width:100}} onClick={()=> deleteAd(ad.id)}>
                        Delete
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Box>
    </div>
  );
}
