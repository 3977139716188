import React from "react";

import Navigation from "./Navigation";
import "bulma/css/bulma.min.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Container } from "react-bulma-components";
import  { useAuth } from "./authProvider";
import { Routes, Route } from "react-router-dom";
import ArticleIndex from "./articles/articleIndex";
import SignIn from "./signIn";
import { ProtectedRoute } from "./ProtectedRoute";
import AdIndex from "./ads/adIndex";

import NewAd from "./ads/newAd";
import EditAd from "./ads/editAd";


const queryClient = new QueryClient();
function App() {
  let { token, setToken } = useAuth();
  if(!token){
    let storedToken = localStorage.getItem('token');
    if(storedToken) {
      setToken(storedToken);
    }
  }
  console.log(token);
  return (
    <QueryClientProvider client={queryClient}>
      <Container>
        <Navigation />
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route
            path="/articles"
            element={
              <ProtectedRoute>
                <ArticleIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ads"
            element={
              <ProtectedRoute>
                <AdIndex />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ads/new"
            element={
              <ProtectedRoute>
                <NewAd />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ads/edit/:id"
            element={
              <ProtectedRoute>
                <EditAd />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Container>
    </QueryClientProvider>
  );
}

export default App;
