import axios from 'axios';

const instance = axios.create({
    baseURL: "https://api.thegeorgiavirtue.com",
});

instance.interceptors.response.use((response) => response, (error)=> {
    if (error.response.status === 401) {
        console.log('auth error redirect to login page');
        window.location.href = '/login';
    }
});

export default instance;

