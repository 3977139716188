import type { FC } from 'react';
import AdForm from './adForm';

interface NewAdProps {}

const NewAd: FC<NewAdProps> = () => {
    return (
        <AdForm  isNew={true}/>
    );
}

export default NewAd;
