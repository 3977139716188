import { Navigate } from 'react-router-dom';
import { useAuth } from '../src/authProvider';
type Props = {
    children: string | JSX.Element
}
export const ProtectedRoute = ({children}: Props): any => {
    const { token } = useAuth();
    if(!token) {
        console.log("navigating to login");
        //return <Navigate to="/login"/>
    }

    return children;
}