import { useQuery } from '@tanstack/react-query';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import instance from '../api';
import AdForm from './adForm';

interface EditAdProps {
    
}

const EditAd : FC<EditAdProps> = () => {
    const { id } = useParams();
    const adQuery = useQuery({
        queryKey: ["ad", id],
        refetchOnMount: true,
        enabled: true,
        queryFn: async () => {
          return await instance.get(`/ads/${id}`).then((res) => {
            return res.data;
          });
        },
      });
      if(adQuery.isLoading){return<p>Loading</p>}
        console.log(adQuery.data);
        let defaultValues = {
            name:adQuery.data.name,
            img: adQuery.data.img,
            url: adQuery.data.url,
            isEnabled: adQuery.data.isEnabled,
            order: adQuery.data.order
        }
        console.log(defaultValues.name)
        return (<AdForm  isNew ={false} id={adQuery.data.id} values={defaultValues}  />);
}

export default EditAd ;
