import { useForm } from "@tanstack/react-form";
import { useAuth } from "./authProvider";
import instance from "./api";
import { useNavigate } from "react-router-dom";
export default function SignIn() {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values: any) => {
      console.log(values.value);
      await instance
        .post("/auth/login", values.value)
        .then((res) => {
          console.log(res);
          setToken(res.data.accessToken);
          navigate('/ads',{replace:true})
        });
    },
  });
  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-6-tablet is-5-desktop is-4-widescreen">
              <form
                className="box is-shadowless"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  form.handleSubmit();
                }}
              >
                <h1 className="title">Please Sign In</h1>
                <div className="card">
                  <div className="card-content">
                    <div className="field">
                    <label htmlFor="" className="label">Email</label>
                      <form.Field
                        name="email"
                        children={(field) => (
                          <input
                            className="input"
                            name={field.name}
                            value={field.state.value}
                            onBlur={field.handleBlur}
                            onChange={(e) => field.handleChange(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="field">
                    <label htmlFor="" className="label">Password</label>
                      <form.Field
                        name="password"
                        children={(field) => (
                          <input
                            className="input"
                            name={field.name}
                            type="password"
                            value={field.state.value}
                            onBlur={field.handleBlur}
                            onChange={(e) => field.handleChange(e.target.value)}
                          />
                        )}
                      />
                    </div>
                    <div className="field buttons">
                      <button className="button is-fullwidth is-success" type="submit">Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
