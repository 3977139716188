import {Navbar } from "react-bulma-components";
import { NavLink } from "react-router-dom";

export default function Navigation() {
  return (
    <Navbar>
      <Navbar.Brand>
        <Navbar.Item>
          <img
            src="https://cdn.thegeorgiavirtue.com/wp-content/uploads/2023/07/tgvlogoheader.png"
            height="128px"
            alt="TGV Logo"
          />
        </Navbar.Item>
      </Navbar.Brand>
      <Navbar.Item><NavLink to='/articles'>Articles</NavLink></Navbar.Item>
      <Navbar.Item><NavLink to='/ads'>Ads</NavLink></Navbar.Item>
    </Navbar>
  );
}