import { useQuery } from '@tanstack/react-query';
import { Box, Button, Table } from 'react-bulma-components';
import instance from '../api';




export default function ArticleIndex() {

    const query = useQuery({queryKey: ['posts'], queryFn: async ()=>{
      const response = await instance.get(`posts`);
      return await response.data;
    }})
  
    async function refreshList() {
      await instance.post('/posts/refresh');
      
      query.refetch();
    }
  
    async function skipPost(id: number) {
      const response = await instance.put(`/posts/skip/${id}`);
      query.refetch();
      return response;
    }

    async function generateNewsletter() {
      const response = await instance.post(`/posts/generate`);
      return response;
    }
  
    async function unskipPost(id: number) {
      const response = await instance.put(`/posts/unskip/${id}`);
      query.refetch();
      return response;
    }
    if(query.isPending) return <p>'Loading...'</p>;
    if (query.error) return <p>'An Error Occured'</p>
    return (
      
      <>
      <Box style={{ width: 1000, margin: 30, padding:20 }}>
        <div className="buttons">
    <Button className="button is-primary"
      onClick={refreshList}
    >Refresh</Button>
    <Button className="button is-primary"
      onClick={generateNewsletter}
    >Generate Email</Button>
    <Button className="button is-primary"
      onClick={refreshList}
    >Mark Sent</Button>
  </div>
      </Box>
      <Box style={{ width: 1000, margin: 30, padding:10 }}>
        <Table>
          <thead>
            <tr>
              <th>title</th>
            </tr>
          </thead>
          <tbody>
        {query.data.map((post: any)=>{
          return (
            <tr key={post.id}>
              <td>
                <a href={post.url}>{post.title}</a>
              </td>
              <td>
                
              </td>
              <td>
                {post.isSkip === true ?(
                <Button onClick={()=>unskipPost(post.id)} color="info" renderAs="span">
                  Unskip
                </Button>):(
                <Button onClick={()=>skipPost(post.id)} color="info" renderAs="span">
                  Skip
                </Button>)
                }
              </td>
            </tr>
          );
        })}
        </tbody>
        </Table>
      </Box>
      </>
    )
  }